import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import ReactHtmlParser from "react-html-parser";

import "./DiscordComponent.css";

export default function DiscordComponent() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("TitleDiscordInvite")}</title>
            </Helmet>
            <div id='landing'>
                <div class='cover'>
                    <div class='cont-1'>
                        <img id='logodiscord' src='/assets/img/discord/discordLogo.png' alt="discordLogo" />
                        <h1>{ReactHtmlParser(t("get_more_with_bot"))}</h1>
                        <p>{t("A helper bot and more")}</p>
                        <a class='btn-invite-server' target="_blank" href="https://discord.com/oauth2/authorize?client_id=1213539505961828412" rel="noopener noreferrer">
                            <b>{t("INVITE TO SERVER")}</b>
                        </a>
                    </div>
                </div>
            </div>
            <div id='commands'>
                <h1>{t("Commands")}</h1>
                <p>{t("Prefix")}: <span class='command-p-h'>&ensp; /</span>
                <div class='command-p legend'>
                    <span class="option-tag required-option">{t("required")}</span>
                    <span class="option-tag optional-option">{t("optional")}</span>
                </div>
                </p>
                <div class='command-grid'>
                    <div class='command-box'>
                        <span class='row sno-com'>1 &ensp; <div class='command-orange'>ping</div></span>
                        <p class='command-p'><b>{t("command_description_1")}</b></p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>2 &ensp;  <div class='command-orange'>invite</div></span>
                        <p class='command-p'><b>{t("command_description_2")}</b></p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>3 &ensp;  <div class='command-orange'>settings</div></span>
                        <p class='command-p'><b>TODO</b></p>
                        <p class='command-p'>{t("Role")}:&ensp;&ensp;&ensp;&ensp;
                            <span class="option-tag optional-option">OGameToolsAdmin</span>
                        </p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>4 &ensp;  <div class='command-orange'>battleconverter-register</div></span>
                        <p class='command-p'><b>{t("command_description_3")}</b></p>
                        <p class='command-p'>{t("Role")}:&ensp;&ensp;&ensp;&ensp;
                            <span class="option-tag optional-option">OGameToolsAdmin</span>
                        </p>
                        <p class='command-p'>{t("Options")}:&ensp;
                            <span class="option-tag optional-option">name</span>
                            <span class="option-tag optional-option">min-df</span>
                            <span class="option-tag optional-option">max-df</span>
                            <span class="option-tag optional-option">min-dmg</span>
                            <span class="option-tag optional-option">max-dmg</span>
                        </p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>5 &ensp;  <div class='command-orange'>battleconverter-unregister</div></span>
                        <p class='command-p'><b>{t("command_description_4")}</b></p>
                        <p class='command-p'>{t("Role")}:&ensp;&ensp;&ensp;&ensp;
                            <span class="option-tag optional-option">OGameToolsAdmin</span>
                        </p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>6 &ensp;  <div class='command-orange'>battleconverter-print-key</div></span>
                        <p class='command-p'><b>{t("command_description_5")}</b></p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>7 &ensp;  <div class='command-orange'>community-follow</div></span>
                        <p class='command-p'><b>{t("command_description_6")}</b></p>
                        <p class='command-p'>{t("Role")}:&ensp;&ensp;&ensp;&ensp;
                            <span class="option-tag optional-option">OGameToolsAdmin</span>
                        </p>
                        <p class='command-p'>{t("Options")}:&ensp;
                            <span class="option-tag required-option">server-id</span>
                            <span class="option-tag optional-option">min-df</span>
                            <span class="option-tag optional-option">max-df</span>
                            <span class="option-tag optional-option">min-dmg</span>
                            <span class="option-tag optional-option">max-dmg</span>
                        </p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>8 &ensp;  <div class='command-orange'>community-unfollow</div></span>
                        <p class='command-p'><b>{t("command_description_7")}</b></p>
                        <p class='command-p'>{t("Role")}:&ensp;&ensp;&ensp;&ensp;
                            <span class="option-tag optional-option">OGameToolsAdmin</span>
                        </p>
                        <p class='command-p'>{t("Options")}:&ensp;
                            <span class="option-tag required-option">server-id</span>
                        </p>
                    </div>

                    <div class='command-box'>
                        <span class='row sno-com'>9 &ensp;  <div class='command-orange'>community-top1-solo</div></span>
                        <p class='command-p'><b>{t("command_description_9")}</b></p>
                        <p class='command-p'>{t("Options")}:&ensp;
                            <span class="option-tag required-option">server-id</span>
                            <span class="option-tag required-option">type</span>
                        </p>
                    </div>
                    <div class='command-box'>
                        <span class='row sno-com'>10 &ensp;  <div class='command-orange'>community-top1-acs</div></span>
                        <p class='command-p'><b>{t("command_description_10")}</b></p>
                        <p class='command-p'>{t("Options")}:&ensp;
                            <span class="option-tag required-option">server-id</span>
                            <span class="option-tag required-option">type</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}