import React from "react";
import ACSAttackResults from './ACSAttackResults/ACSAttackResults';
import FleetOverview from './FleetOverview/FleetOverview';
import RecyclerOverview from './RecyclerOverview/RecyclerOverview';
import Result from './Result/Result';

import "./Panel.css";

export default function Panel({ side, combatReports, setCombatReports, recycleReports, setRecycleReports, playerTotalsStatistics, setPlayerTotalsStatistics, dataFromSharedReport, settingsData, acsWhoAmI}) {
    return (
        <div className="panel">
            {side === 0 ?
                <ACSAttackResults
                    combatReports={combatReports}
                    setCombatReports={setCombatReports}
                    settingsData={settingsData}
                    side={side}
                /> : ""}
            <FleetOverview
                combatReports={combatReports}
                setCombatReports={setCombatReports}
                side={side}
                settingsData={settingsData}
                dataFromSharedReport={dataFromSharedReport}
                acsWhoAmI={acsWhoAmI}
            />
            <RecyclerOverview
                recycleReports={recycleReports}
                setRecycleReports={setRecycleReports}
                dataFromSharedReport={dataFromSharedReport}
                acsWhoAmI={acsWhoAmI}
            />
            <Result 
                combatReports={combatReports} 
                recycleReports={recycleReports}
                settingsData={settingsData}
                playerTotalsStatistics={playerTotalsStatistics}
                setPlayerTotalsStatistics={setPlayerTotalsStatistics}
                side={side}
                dataFromSharedReport={dataFromSharedReport}
            />
        </div>
    )
}