import "./autoCompleteTextbox.css";

import React, {useState, useRef, useEffect} from "react";

const ReactAutoCompleteTagsInput = ({items, addItemHandler, deleteItemHandler, filterOptionsHandler, validateHandler, emptyTagsText}) => {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const itemsContainer = useRef();
  const [id, setId] = useState(null);

  useEffect(() => {
    if(id == null){
      setId(generateRandomId());
    }
  });

  const generateRandomId = () => {
    return Math.floor(Math.random()*10000);
  }

  const toggleAddNewItemClickHandler = (evnt) => {
    evnt.stopPropagation();

    if(!isAddingNewItem){
      setIsAddingNewItem(true);
    }
    else{
      setIsAddingNewItem(false);
    }

    return false;
  }

  const selectNewItemHandler = (item) => {
    addItemHandler(item);
    setIsAddingNewItem(false);
  }

  const newItemBlurEventHandler = (evnt) => {
    evnt.stopPropagation();
    setIsAddingNewItem(false);
  }

  return (
    <div className="autocompleteTextBox" id={`autocompleteTextBox-${id}`}>
      <div ref={itemsContainer} className={`autocompleteTextBox__items${isAddingNewItem ? ' autocompleteTextBox__items-active' : ''}`} onClick={evnt => toggleAddNewItemClickHandler(evnt)} >
        <Items items={items} deleteItemHandler={deleteItemHandler} emptyTagsText={emptyTagsText}/>
        <NewItem filterOptionsHandler={filterOptionsHandler} selectedItems={items} selectNewItemHandler={selectNewItemHandler} onBlurHandler={newItemBlurEventHandler} validateHandler={validateHandler} shouldShow={isAddingNewItem} id={id}/>
      </div>
    </div>
  );
}

const Items = ({items, deleteItemHandler, emptyTagsText}) => {
  if(items.length === 0){
    return null;
  }

  return (
    <React.Fragment>
      {items.map(
        (item, index) => {
          return (
            <AutoCompleteItem key={index} item={item} deleteItemHandler={deleteItemHandler}/>
          );
        }
      )}
    </React.Fragment>
  );
}

const AutoCompleteItem = ({itemIndex, item, deleteItemHandler}) => {
  return (
    <div className="autocompleteTextBoxItem" onClick={evnt => evnt.stopPropagation()}>
      <span className="autocompleteTextBoxItem__text">{item.ServerName}</span>
      <button className="autocompleteTextBoxItem__remove" onClick={event => deleteItemHandler(event, item.ServerName)}>
        <svg width="15" height="15" viewBox="-1 -2 26 26" focusable="false" role="presentation">
          <path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"></path>
        </svg>
      </button>
    </div>
  );
}

const NewItem = ({ filterOptionsHandler, selectedItems, selectNewItemHandler, onBlurHandler, validateHandler, shouldShow, id }) => {
    const [showWarning, setShowWarning] = useState(false);
    const txtBoxRef = useRef(null);
    const newItemRef = useRef(null);
    const idRef = useRef(id);
    const [clickOutEventRegistered, setClickOutEventRegistered] = useState(0);
    const [newItemsOptions, setNewItemsOptions] = useState([]);
  
    useEffect(() => {
      txtBoxRef.current.focus();
  
      setNewItemsOptions(getNotSelectedOptions(''));
  
      if (!clickOutEventRegistered && id != null) {
        idRef.current = id;
  
        document.addEventListener("click", documentClickEventListener);
        setClickOutEventRegistered(true);
      }
    }, [txtBoxRef, shouldShow]);
  
    const newItemUpdatedEventHandler = (evnt) => {
      const value = evnt.target.textContent.trim();
      let options = getNotSelectedOptions(value);
    
      // Check if value already exists in selectedItems
      const exists = selectedItems.some(item => item.Secret === value);
    
      if (!exists) {
        options.unshift({ ServerName: "", Secret: value }); // Add new object to the beginning of the array
      }
    
      setNewItemsOptions(options);
    }
    
    const getNotSelectedOptions = (filterText) => {
      let options = filterOptionsHandler(filterText);  
      return options.filter(o => !selectedItems.some(item => item.Secret === o.Secret));
    }
  
    const documentClickEventListener = (evnt) => {
      let clickOutElement = document.getElementById(newItemRef.current.id);
  
      if (clickOutElement == null) return;
  
      if (clickOutElement.className.includes('hidden')) return;
  
      // If the user is clicking into the items area of an open drop down then do nothing
      if (evnt.target === document.querySelector(`#autocompleteTextBox-${idRef.current} .autocompleteTextBox__items`)
        || evnt.target === document.querySelector(`#autocompleteTextBox-${idRef.current} .autocompleteTextBoxAddBtn`)) {
        txtBoxRef.current.innerText = '';
        return;
      }
  
      let targetElement = evnt.target;
  
      do {
        if (targetElement === clickOutElement) {
          return;
        }
        // Go up the DOM.
        targetElement = targetElement.parentNode;
      } while (targetElement);
  
      txtBoxRef.current.innerText = '';
      onBlurHandler(evnt);
    }
  
    /*const selectItemHandler = (item) => {
      txtBoxRef.current.innerText = '';
      selectNewItemHandler(item);
    }*/

    const selectItemHandler = async (item) => {
      txtBoxRef.current.innerText = '';
      try {
          const itemExists = await validateHandler(item);
          if (itemExists) {
              setShowWarning(false);
              selectNewItemHandler(itemExists);
          } else {
              setShowWarning(true);
          }
      } catch (error) {
          console.error("Error validating item:", error);
          setShowWarning(true);
      }
    };
  

    const handleKeyPress = async (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default behavior of Enter key
        const value = event.target.textContent.trim();
        // Check if the value is not empty and does not already exist in the selectedItems
        if (value && selectedItems.indexOf(value) === -1) {
          try { 
            const itemExists = await validateHandler({ Secret: value });
            if (itemExists) {
              setShowWarning(false);
              selectNewItemHandler(itemExists); // Add the new item
            } else {
                setShowWarning(true);
            }
        } catch (error) {
            console.error("Error validating item:", error);
            setShowWarning(true);
        }}
      }
    };
  
    return (
      <div ref={newItemRef} id={`autocompleteTextBoxNewItem-${id}`} className={`autocompleteTextBoxNewItem${shouldShow ? '' : ' hidden'}`}>
        <div className="autocompleteTextBoxNewItem__container">
          <div ref={txtBoxRef} className={`autocompleteTextBoxNewItem__text${showWarning ? ' warning' : ''}`} contentEditable="true" onClick={evnt => evnt.stopPropagation()} onInput={evnt => newItemUpdatedEventHandler(evnt)} onKeyDown={handleKeyPress}></div>
          <ul className="autocompleteTextBoxNewItem__list">
            {newItemsOptions.map(
              (item, index) => {
                return (
                  <li key={index} className="autocompleteTextBoxNewItem__listitem" onClick={evnt => selectItemHandler(item)}>
                    {item.ServerName && <span>{item.ServerName}-</span>}
                    {item.Secret}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    );
  }
  

export default ReactAutoCompleteTagsInput;