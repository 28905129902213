import axios from 'axios';
import config from '../../config.json';

export const FetchRecycleReport = (recycleReport, RecycleReports, setRecycleReports, setApiKeyInputValidity, setLoading, setApiKeyInputValidityMessage) => {
    let exists = RecycleReports.find(rr => rr.id === recycleReport.trim());
    if (!exists) {
        setLoading(true);

        let apiUrl = config.develop ? config.develop_api : config.api;
        const request = {
            method: "GET",
            url: `${apiUrl}/fetch-ogame-report/${recycleReport.trim()}`,
            headers: {
                'Custom-Language': localStorage.getItem("language") || "en",
            },
        };

        axios(request)
            .then(function (response) {
                let data = response.data;

                if (RecycleReports.length > 0 && RecycleReports[0].coordinates !== data.data.generic.coordinates) {
                    setApiKeyInputValidity(false);
                    setApiKeyInputValidityMessage("InvalidCoordinates");
                    setLoading(false);
                } else {
                    let rrdata = {
                        id: data.id,
                        coordinates: data.data.generic.coordinates,
                        metal: data.data.generic.recycler_metal_retrieved + data.data.generic.reaper_metal_retrieved,
                        crystal: data.data.generic.recycler_crystal_retrieved + data.data.generic.reaper_crystal_retrieved,
                        deuterium: data.data.generic.recycler_deuterium_retrieved + data.data.generic.reaper_deuterium_retrieved,
                        ownerId: data.data.generic.owner_id,
                        ownerName: data.data.generic.owner_name,
                        deuteriumConsumption: 0,
                        recyclers: data.data.generic.recycler_count,
                        reapers: data.data.generic.reaper_count
                    };

                    let rrs = [...RecycleReports];
                    rrs.push(rrdata);
                    setApiKeyInputValidity(true);
                    setApiKeyInputValidityMessage("");
                    setRecycleReports(rrs);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setApiKeyInputValidity(false);
                console.log(error);
                if (error.response)
                    switch (error.response.status) {
                        case 400:
                            setApiKeyInputValidityMessage("InvalidKey");
                            break;
                        case 500:
                            setApiKeyInputValidityMessage("ServerError");
                            break;
                        default:
                            setApiKeyInputValidityMessage("UnknownError");
                    }
                else
                    setApiKeyInputValidityMessage("UnknownError");
            })
    } else {
        setApiKeyInputValidity(false);
        setApiKeyInputValidityMessage("KeyAlreadyExists");
    }
}