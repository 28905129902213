import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { CircleLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async';
import { FormatUnits } from '../../helpers/FormatUnits';
import axios from 'axios';
import config from '../../config.json';
import './CombatReports.css';

export default function CombatReports() {
    const { t } = useTranslation();
    const { language } = useParams();

    const [loadingReports, setLoadingReports] = useState(true);
    const [loadingTopReports, setLoadingTopReports] = useState(true);
    const [reports, setReports] = useState([]);
    const [reportsCount, setReportsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [topHits, setTopHits] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [maxPage, setMaxPage] = useState(0);

    const [publicReportErrors, setPublicReportErrors] = useState("");
    const [publicTopReportsErrors, setPublicTopReportsErrors] = useState("");

    const [SettingsData, setSettingsData] = useState(JSON.parse(localStorage.getItem('battleReportsData')) || {
        community: "",
        filters: {
            type: "1",
            grouped: "0"
        }
    });

    useEffect(() => {
        let apiUrl = config.develop ? config.develop_api : config.api;
    
        const genericReportRequest = axios.post(`${apiUrl}/reports`, {
            options: {
                page: currentPage
            }
        });
    
        setLoadingReports(true); // Set loading to true before making requests
    
        genericReportRequest
            .then(function (response) {
                const genericReportResponse = response.data;
    
                setReports(genericReportResponse.reports);
                setReportsCount(genericReportResponse.reports_count);
                setCurrentPage(genericReportResponse.current_page);
                setMaxPage(genericReportResponse.max_page);
                setPublicReportErrors("")
                setLoadingReports(false);
            })
            .catch(function (error) {
                console.log(error);
                setPublicReportErrors(t("unknown_error_contact_admins"))
                setLoadingReports(false);
            });
    }, [currentPage]);

    useEffect(() => {
        let apiUrl = config.develop ? config.develop_api : config.api;
    
        const topHitsRequest = axios.post(`${apiUrl}/top-hits`, {
            top_filters: {
                community: SettingsData.community,
                report_type: SettingsData.filters.type,
                grouped: SettingsData.filters.grouped
            }
        });
    
        setLoadingTopReports(true);
    
        topHitsRequest
            .then(function (response) {
                const topHitsResponse = response.data;    
                setTopHits(topHitsResponse.top_hits);
                setCommunities(topHitsResponse.communities)
                setPublicTopReportsErrors("")
                setLoadingTopReports(false);
            })
            .catch(function (error) {
                console.log(error);
                setPublicTopReportsErrors(t("unknown_error_contact_admins"))
                setLoadingTopReports(false);
            });
    }, [SettingsData]);

    const handleRowClick = (reportId) => {
        window.location.href = `/${language}/report/${reportId}`;
    };

    const handleReportTypeChange = (e) => {
        const newSettingsData = {
            ...SettingsData,
            filters: {
                ...SettingsData.filters,
                type: e.target.value
            }
        };
        localStorage.setItem('battleReportsData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };

    const handleGroupedChange = (e) => {
        const newSettingsData = {
            ...SettingsData,
            filters: {
                ...SettingsData.filters,
                grouped: e.target.value
            }
        };
        localStorage.setItem('battleReportsData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };
    
    const handleCommunityChange = (community) => {
        const newSettingsData = {
            ...SettingsData,
            community: community
        };
        localStorage.setItem('battleReportsData', JSON.stringify(newSettingsData));
        setSettingsData(newSettingsData);
    };

    return (
        <>
            <Helmet>
                <title>{t("TitleBattleReports")}</title>
            </Helmet>
            <div id="report-header">
                <h2 className="subtitle">{t("TitleBattleReports")}</h2>
            </div>
            <table class="tablesorter ally_ranks tabstatistica" style={{marginBottom: '10px', textAlign: 'right'}}>
                <tbody>
                    <tr class="classificabarra ">
                        <th></th>
                        <th>{t("Community")}</th>
                        <th>{t("Universe")}</th>
                        <th>{t("Attacker(s)")}</th>
                        <th>{t("Defender(s)")}</th>
                        <th>
                        {SettingsData.filters.type === "1" ? t("Damage") : 
                        SettingsData.filters.type === "2" ? t("DebrisField") :
                        t("Damage")}
                        </th>
                        
                    </tr>

                    {loadingTopReports ? (
                        <tr>
                            <td colspan="7" style={{ textAlign: 'center' }}>
                                <CircleLoader color={'#73cdf0e6'} loading={loadingTopReports} size={50} cssOverride={{ display: 'inline-table' }} />
                            </td>
                        </tr>
                    ) : (
                        <>
                        {topHits.length > 0 ? (
                            <>
                            {topHits.map((report, index) => (
                                <tr key={index} className="classificabarra line" onClick={() => handleRowClick(report.id)}>
                                    <td><img src={`/assets/img/trophees/${index+1}trof.webp`} alt="" style={{ height: '16px', display: 'block', margin: 'auto' }} /></td>
                                    <td><img src={`/assets/img/flags/${report.community}.webp`} alt="" style={{ height: '16px', display: 'block', margin: 'auto' }} /></td>
                                    <td>{report.universe}</td>
                                    <td>
                                        <div className="barracla"></div>
                                        <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{report.attackers}</span>
                                    </td>
                                    <td>
                                        <div className="barracla"></div>
                                        <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{report.defenders}</span>
                                    </td>
                                    <td className="report-summary-positive">
                                        {SettingsData.filters.type === "1" ? FormatUnits(report.summary.TotalDammage) : 
                                        SettingsData.filters.type === "2" ? FormatUnits(report.summary.TotalDebrisField) :
                                        FormatUnits(report.summary.TotalDammage)}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="7">
                                    <div id="filter-community-wrapper" style={{ float: 'left' }}>
                                        <div id="filter-community">
                                            <img style={{ height: '16px', display: 'block', margin: 'auto' }} src={SettingsData.community ? `/assets/img/flags/${SettingsData.community}.webp` : '/assets/img/flags/world.webp'} alt="" />
                                        </div>
                                        <ul>
                                            <li key="world" onClick={() => handleCommunityChange("")}>
                                                <img style={{ height: '16px'}} src={`/assets/img/flags/world.webp`} alt="world" />
                                            </li>
                                            {communities.map(community => (
                                                <li key={community} onClick={() => handleCommunityChange(community)}>
                                                    <img style={{ height: '16px'}} src={`/assets/img/flags/${community}.webp`} alt={community} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <select style={{ float: 'right' }} className="top-type-select" value={SettingsData.filters.type} onChange={handleReportTypeChange}>
                                        <option value="1">{t("Damage")}</option>
                                        <option value="2">{t("DebrisField")}</option>
                                    </select>
                                    <select style={{ float: 'right' }} className="top-type-select" value={SettingsData.filters.grouped} onChange={handleGroupedChange}>
                                        <option value="0">{t("Solo")}</option>
                                        <option value="1">{t("Grouped")}</option>
                                    </select>
                                </td>
                            </tr>
                            </>
                          ) : (
                            <>
                            {publicTopReportsErrors !== "" ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                    {publicTopReportsErrors}
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                    {t("No reports available")}
                                    </td>
                                </tr>
                            )}
                            </>
                        )}
                        </>
                    )}
                </tbody>
            </table>
            {/* <div class="options-panel" style={{margin: '10px 0 10px 0'}}>
                <div class="options-panel-header">
                    <span class="options-title">Filters</span>
                    <span class="options-panel-icon">▶</span>
                </div>
                <div class="options-panel-content"></div>
            </div> */}
            <table class="tablesorter ally_ranks tabstatistica">
                <tbody>
                    <tr class="classificabarra ">
                        <th>{t("Community")}</th>
                        <th>{t("Universe")}</th>
                        <th>{t("Attacker(s)")}</th>
                        <th>{t("Defender(s)")}</th>
                        <th>{t("Att Summary")}</th>
                        <th>{t("Def Summary")}</th>
                        <th>{t("Date")}</th>
                    </tr>

                    {loadingReports ? (
                        <tr>
                            <td colspan="7" style={{ textAlign: 'center' }}>
                                <CircleLoader color={'#73cdf0e6'} loading={loadingReports} size={50} cssOverride={{ display: 'inline-table' }} />
                            </td>
                        </tr>
                    ) : (
                        <>
                        {reports.length > 0 ? (
                        reports.map((report, index) => (
                            <tr key={index} className="classificabarra line" onClick={() => handleRowClick(report.id)}>
                                <td><img src={`/assets/img/flags/${report.community}.webp`} alt="" style={{ height: '16px', display: 'block', margin: 'auto' }} /></td>
                                <td>{report.universe}</td>
                                <td>
                                    <div className="barracla"></div>
                                    <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{report.attackers}</span>
                                </td>
                                <td>
                                    <div className="barracla"></div>
                                    <span className="fbox-s-name f-ua classstat5" style={{ color: '#fff' }}>{report.defenders}</span>
                                </td>
                                <td className={`report-summary-${report.summary.AttackerPos ? 'positive' : 'negative'}`}>{FormatUnits(report.summary.AttackerLoss)}</td>
                                <td className={`report-summary-${report.summary.DefenderPos ? 'positive' : 'negative'}`}>{FormatUnits(report.summary.DefenderLoss)}</td>
                                <td>{report.date}</td>
                            </tr>
                        ))
                        ) : (
                            <>
                            {publicReportErrors !== "" ? (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                    {publicReportErrors}
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>
                                    {t("No reports available")}
                                    </td>
                                </tr>
                            )}
                            </>
                        )}
                        </>
                    )}
                </tbody>
            </table>
            {publicReportErrors === "" && (
                <div class="tablesorter pagination" style={{marginTop: '10px', textAlign: 'right'}}>
                    <span style={{ marginRight: '10px', color: '#c0c7d2' }}>{`${25*(currentPage-1)+1}-${(25*(currentPage-1))+25} of ${reportsCount}`}</span>
                    <button style={{ marginRight: '5px' }} disabled={currentPage === 1 || loadingReports} onClick={() => setCurrentPage(currentPage - 1)}>{t("Previous")}</button>
                    <button style={{ marginRight: '5px' }} disabled={currentPage === maxPage || loadingReports} onClick={() => setCurrentPage(currentPage + 1)}>{t("Next")}</button>
                </div>
            )}
        </>
    );
}