import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import "./SelectPlayerSide.css";

export default function SelectPlayerSide({ setSide }) {
    const { t } = useTranslation();

    return (
        <div className="select-player-side">
            <div className="label">
                {t("Which side are you on")}
            </div>
            <Button
                disabled={false}
                onClick={() => setSide(0)}
            >
                {t("Attacker(s)")}
            </Button>
            <Button
                disabled={false}
                onClick={() => setSide(1)}
            >
                {t("Defender(s)")}
            </Button>
        </div>
    )
}