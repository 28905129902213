import React from "react";
import { useTranslation } from "react-i18next";

import "./NotFound.css";

export default function NotFound({code=404, message=""}) {
    const { t } = useTranslation();

    if (message === "") {
        message = t("PageNotFound")
    }

    return (
        <div id="not-found-page">
            <section>
                <span>{code}</span>
                <p>{message}</p>
            </section>
        </div>
    )
}