import React, { useState, useEffect } from 'react';

import TextInput from '../../../../components/TextInput/TextInput';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';

import { useTranslation } from "react-i18next";
import './RecyclerOverview.css';

export default function RecyclerOverview({ recycleReports, setRecycleReports, dataFromSharedReport, acsWhoAmI }) {
    const { t } = useTranslation();
    const [DataVisible, setDataVisible] = useState(true)
    const [RecyclerOverviewDetails, setRecyclerRecyclerOverviewDetails] = useState([])

    const updateReport = (index, type, value) => {
        let reportsNew = [...recycleReports];
        reportsNew[index][type] = !isNaN(value) ? value > 0 ? value : 0 : 0;
        setRecycleReports(reportsNew);
    }

    useEffect(() => {
        setRecyclerRecyclerOverviewDetails(recycleReports.map((report, index) => {
            return (
                <div
                    className="recycler-report-details"
                    key={`RECYCLEREPORTDETAILS${index}`}
                >
                    <div className={`card ${report.ownerName.toLowerCase() === acsWhoAmI.toLowerCase() ? 'own' : ''}`}>
                        <div className="label mt-5">{`${t("Harvest")} #${index + 1}`} - {report.ownerName}</div>

                        <div className="label mt-5">{t("FlightConsumption")}</div>
                        <div className="input-row mt-5">
                            {t("Deuterium")}
                            <TextInput
                                value={report.deuteriumConsumption}
                                onChange={event => updateReport(index, "deuteriumConsumption", parseInt(event.target.value))}
                                valid={report.deuteriumConsumption <= 0 ? false : true}
                            />
                        </div>

                        <div className="label mt-5">{t("FleetComposition")}</div>
                        <div className="input-row mt-5">
                            {t("Recycler")}
                            <TextInput
                                value={report.recyclers}
                                onChange={event => updateReport(index, "recyclers", parseInt(event.target.value))}
                                valid={report.recyclers < 0 ? false : true}
                                readOnly={dataFromSharedReport}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("Reaper")}
                            <TextInput
                                value={report.reapers}
                                onChange={event => updateReport(index, "reapers", parseInt(event.target.value))}
                                valid={report.reapers < 0 ? false : true}
                                readOnly={dataFromSharedReport}
                            />
                        </div>

                        <div className="label mt-5">{t("Resources")}</div>
                        <div className="input-row mt-5">
                            {t("Metal")}
                            <TextInput
                                value={report.metal}
                                onChange={event => updateReport(index, "metal", parseInt(event.target.value))}
                                valid={report.metal < 0 ? false : true}
                                readOnly={dataFromSharedReport}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("Crystal")}
                            <TextInput
                                value={report.crystal}
                                onChange={event => updateReport(index, "crystal", parseInt(event.target.value))}
                                valid={report.crystal < 0 ? false : true}
                                readOnly={dataFromSharedReport}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("Deuterium")}
                            <TextInput
                                value={report.deuterium}
                                onChange={event => updateReport(index, "deuterium", parseInt(event.target.value))}
                                valid={report.deuterium < 0 ? false : true}
                                readOnly={dataFromSharedReport}
                            />
                        </div>
                    </div>
                </div>
            );
        }))
    }, [recycleReports, t])

    return (
        <div className="recycler-pickups-main">
            <SectionTitle
                title={t("Harvests Overview")}
                icon={DataVisible ? "caret-up" : "caret-down"}
                onClick={() => setDataVisible(!DataVisible)}
            />
            <div
                className="recycler-pickups"
                style={{ display: !DataVisible || recycleReports.length === 0 ? "none" : "flex" }}
            >
                <div className="recycle-icon">
                    <i className="fas fa-recycle" />
                </div>
                <div className="recyclers-details-panel">
                    {RecyclerOverviewDetails}
                </div>
            </div>
        </div>
    )
}