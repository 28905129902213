import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config.json';

export default function Popup({ res, setLoading }) {

  useEffect(() => {
    const popup = document.querySelector('#popup');
    const popupContent = document.querySelector('#popup-content', popup);
    const container = document.querySelector('#popup-container', popup);
    if (popupContent && res.Data.preview) {
      popupContent.innerHTML = res.Data.preview;
      popup.style.display = 'block'
      container.style.marginLeft = `-${container.offsetWidth / 2}px`;

      const reportShareUrlButton = document.querySelector('#report-share-url');
      if (reportShareUrlButton) {
        reportShareUrlButton.addEventListener('click', handleButtonClick);
      }

      const idShareYesButton = document.querySelector('#share-public-yes');
      if (idShareYesButton) {
        idShareYesButton.addEventListener('click', handleShareYesClick);
      }

      const idShareNoButton = document.querySelector('#share-public-no');
      if (idShareNoButton) {
        idShareNoButton.addEventListener('click', handleShareNoClick);
      }
    }
  }, [res]);

  function handleButtonClick(e) {
    e.preventDefault();
    const sharePublicDiv = document.querySelector('#share-public');

    if (sharePublicDiv) {
      if (sharePublicDiv.style.display === 'none' || sharePublicDiv.style.display === '') {
        sharePublicDiv.style.display = 'block';
      } else {
        sharePublicDiv.style.display = 'none';
      }
    }
  }

  function handleShareYesClick() {
    publishCombatReport(1);
  }

  function handleShareNoClick() {
    publishCombatReport(0);
  }

  function publishCombatReport(updatedIsPublic) {
    const formData = new FormData();
    formData.append('token', res.Data.token);
    formData.append('public', updatedIsPublic);

    let apiUrl = config.develop ? config.develop_api : config.api;

    const request = {
        url: `${apiUrl}/share-combat-report`,
        method: "POST",
        data: formData,
        headers: {
            'Custom-Language': localStorage.getItem("language") || "en",
        },
    };

    axios(request)
        .then((response) => {
            setLoading(false);

            if (response.errors && response.errors.length > 0) {
                //setError(response.errors.join(', '));
            } else {
                // Handle success
                const sharePublicDiv = document.getElementById('share-public');
                sharePublicDiv.style.display = 'none';

                const reportSocialShare = document.getElementById('report-social-share');
                reportSocialShare.style.display = 'none';

                console.log(response)
                const preview = document.getElementById('report-preview');
                preview.insertAdjacentHTML('beforebegin', response.data.preview);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error)
            //setError('An error occurred while processing the request.');
        });
};

  return null
}