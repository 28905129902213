import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import Button from "../Button/Button";

import "./LanxCalculator.css";

export default function LanxCalculator() {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [uniSpeed, setUniSpeed] = useState(5)
    const [donutGalaxy, setDonutGalaxy] = useState(true)
    const [donutSystem, setDonutSystem] = useState(true)
    const [numberOfGalaxies, setNumberOfGalaxies] = useState(7)
    const [travelSpeed, setTravelSpeed] = useState(100)
    const [fleetSpeed, setFleetSpeed] = useState(0)
    const [origin, setOrigin] = useState(["0", "0", "0"])
    const [target, setTarget] = useState(["0", "0", "0"])
    const [secondsBetweenScans, setSecondsBetweenScans] = useState(2)

    const [arrivalTime, setArrivalTime] = useState([0, 0, 0, 0, 0, 0])
    const [recallTime, setRecallTime] = useState([0, 0, 0, 0, 0, 0])
    const [showResult, setShowResult] = useState(false)
    const [travelDistance, setTravelDistance] = useState(0)
    const [travelTime, setTravelTime] = useState("")
    const [returnTime, setReturnTime] = useState("")

    const [isArrivalCoordinatesValid, setIsArrivalCoordinatesValid] = useState(true)
    const [isOriginCoordinatesValid, setIsOriginCoordinatesValid] = useState(true)
    const [isScanDelayValid, setIsScanDelayValid] = useState(true)
    const [isFleetSpeedValid, setIsFleetSpeedValid] = useState(true)
    const [isArrivalTimeValid, setIsArrivalTimeValid] = useState(true)
    const [isRecallTimeValid, setIsRecallTimeValid] = useState(true)

    const calculateReturn = (event, share = false) => {
        event.preventDefault();

        setLoading(true)
        setIsArrivalCoordinatesValid(true)
        setIsOriginCoordinatesValid(true)
        setIsScanDelayValid(true)
        setIsFleetSpeedValid(true)
        setIsArrivalTimeValid(true)
        setIsRecallTimeValid(true)
        setShowResult(false)

        const isGalaxyValid = (value) => value >= 1 && value <= numberOfGalaxies;
        const isSystemValid = (value) => value >= 1 && value <= 499;
        const isPlanetValid = (value) => value >= 1 && value <= 15;
        const isSecondsBetweenScansValid = (value) => value >= 1;

        const [fromGalaxy, fromSystem, fromPlanet] = origin;
        const [toGalaxy, toSystem, toPlanet] = target;

        let hasErrors = false

        const calculateDistance = () => {
            const rawCoords1 = origin;
            const rawCoords2 = target;
            const galaxy1 = origin[0];
            const solar1 = origin[1];
            const slot1 = origin[2];
            const galaxy2 = target[0];
            const solar2 = target[1];
            const slot2 = target[2];

            const moonToPlanet = () => 5;

            const inSystem = (slot1, slot2) => {
                return 1000 + 5 * Math.abs(slot2 - slot1);
            };

            const inGalaxy = (solar1, solar2) => {
                if (!donutSystem) {
                    return 2700 + 95 * Math.abs(solar2 - solar1);
                } else {
                    const route1 = Math.abs(solar2 - solar1);
                    const route2 = 499 - route1;
                    const difference = Math.min(route1, route2);
                    return 2700 + 95 * difference;
                }
            };

            const crossGalaxy = (galaxy1, galaxy2) => {
                if (!donutGalaxy) {
                    return 20000 * Math.abs(galaxy2 - galaxy1);
                } else {
                    const route1 = Math.abs(galaxy2 - galaxy1);
                    const route2 = numberOfGalaxies - route1;
                    const difference = Math.min(route1, route2);
                    return 20000 * difference;
                }
            };

            if (rawCoords1.toString() === rawCoords2.toString()) {
                return moonToPlanet();
            } else if (galaxy1 === galaxy2 && solar1 === solar2) {
                return inSystem(slot1, slot2);
            } else if (galaxy1 === galaxy2) {
                return inGalaxy(solar1, solar2);
            } else {
                return crossGalaxy(galaxy1, galaxy2);
            }
        };

        const getFlightTime = (distance) => {
            const timeInSeconds = Math.round(
                ((35000 / travelSpeed) *
                    (distance * 1000 / fleetSpeed) ** 0.5 +
                    10) /
                uniSpeed
            );
            const hours = Math.floor(timeInSeconds / 3600);
            const remainder = timeInSeconds - hours * 3600;
            const minutes = Math.floor(remainder / 60);
            const seconds = remainder - minutes * 60;
            return [hours, minutes, seconds];
        };

        const secondsToHMS = (seconds) => {
            const days = Math.floor(seconds / 86400); // Number of seconds in a day
            seconds %= 86400;

            const hours = Math.floor(seconds / 3600);
            seconds %= 3600;

            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;

            const formattedDays = days > 0 ? `${days}d ` : '';
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            return `${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        };

        const getReturnTime = (arrivalTime, delay, recallTime) => {
            const distance = calculateDistance();
            setTravelDistance(distance)
            const flightTime = getFlightTime(distance);
            const flightTimeInSeconds = flightTime[0] * 3600 + flightTime[1] * 60 + flightTime[2];
            setTravelTime(secondsToHMS(flightTimeInSeconds))

            const arrival = new Date(
                arrivalTime[2],
                arrivalTime[1] - 1, // Month is 0-indexed in JavaScript Date object
                arrivalTime[0],
                arrivalTime[3],
                arrivalTime[4],
                arrivalTime[5]
            );

            const returnTime = new Date(arrival.getTime() + flightTimeInSeconds * 1000);

            if (!recallTime) {
                return [
                    returnTime.getDate(),
                    returnTime.getMonth() + 1, // Month is 0-indexed, add 1 to get the correct month
                    returnTime.getFullYear(),
                    returnTime.getHours(),
                    returnTime.getMinutes(),
                    returnTime.getSeconds(),
                ];
            } else {
                const recall = new Date(
                    recallTime[2],
                    recallTime[1] - 1, // Month is 0-indexed in JavaScript Date object
                    recallTime[0],
                    recallTime[3],
                    recallTime[4],
                    recallTime[5]
                );

                const recallInterval = arrival - recall;

                // Latest return time
                const lateReturnTime = new Date(returnTime.getTime() - 2 * recallInterval);
                const delayInSeconds = parseInt(delay);

                const earlyReturnTime = new Date(lateReturnTime.getTime() - 2 * delayInSeconds * 1000);

                if (lateReturnTime.getTime() === earlyReturnTime.getTime()) {
                    return [
                        earlyReturnTime.getDate(),
                        earlyReturnTime.getMonth() + 1, // Month is 0-indexed, add 1 to get the correct month
                        earlyReturnTime.getFullYear(),
                        earlyReturnTime.getHours(),
                        earlyReturnTime.getMinutes(),
                        earlyReturnTime.getSeconds(),
                    ];
                } else {
                    return [
                        [
                            earlyReturnTime.getDate(),
                            earlyReturnTime.getMonth() + 1,
                            earlyReturnTime.getFullYear(),
                            earlyReturnTime.getHours(),
                            earlyReturnTime.getMinutes(),
                            earlyReturnTime.getSeconds(),
                        ],
                        [
                            lateReturnTime.getDate(),
                            lateReturnTime.getMonth() + 1,
                            lateReturnTime.getFullYear(),
                            lateReturnTime.getHours(),
                            lateReturnTime.getMinutes(),
                            lateReturnTime.getSeconds(),
                        ],
                    ];
                }
            }
        };

        const isDateTimeValid = (dateTimeArray) => {
            const dateTime = new Date(
                dateTimeArray[2], // Year
                dateTimeArray[1] - 1, // Month (subtract 1 as months are 0-indexed)
                dateTimeArray[0], // Day
                dateTimeArray[3], // Hours
                dateTimeArray[4], // Minutes
                dateTimeArray[5] // Seconds
            );
        
            return !isNaN(dateTime.getTime()) && dateTime.getTime() > 0;
        }

        const processOutput = (digit) => {
            const digitString = digit.toString();
            console.log(digitString.length);
        
            if (digitString.length === 1)
                return "0" + digitString;
        
            return digitString;
        }

        if (
            !isGalaxyValid(fromGalaxy) ||
            !isSystemValid(fromSystem) ||
            !isPlanetValid(fromPlanet)
        ) {
            console.error("Invalid input values. Please check galaxy, system, and planet values.");
            hasErrors = true
            setIsArrivalCoordinatesValid(false)
            setLoading(false)
        }

        if (
            !isGalaxyValid(toGalaxy) ||
            !isSystemValid(toSystem) ||
            !isPlanetValid(toPlanet)
        ) {
            console.error("Invalid input values. Please check galaxy, system, and planet values.");
            hasErrors = true
            setIsOriginCoordinatesValid(false)
            setLoading(false)
        }

        if (fleetSpeed === 0) {
            console.error("Invalid input values. Please check fleet speed value.");
            hasErrors = true
            setIsFleetSpeedValid(false)
            setLoading(false)
        }

        if (!isSecondsBetweenScansValid(secondsBetweenScans)) {
            console.error("Invalid input values. Please check seconds between scans value.");
            hasErrors = true
            setIsScanDelayValid(false)
            setLoading(false)
        }

        if (!isDateTimeValid(arrivalTime)) {
            console.error("Invalid input values. Please check arrival time.");
            hasErrors = true
            setIsArrivalTimeValid(false)
            setLoading(false)
        }

        if (!isDateTimeValid(recallTime)) {
            console.error("Invalid input values. Please check recall time.");
            hasErrors = true
            setIsRecallTimeValid(false)
            setLoading(false)
        }

        if (hasErrors) return;

        if (recallTime.slice(3, 6).every((value) => value === 0)) {
            setRecallTime(false);
        }

        let returnTime = getReturnTime(arrivalTime, secondsBetweenScans, recallTime)

        if (returnTime.length === 6) {
            setReturnTime(`[${returnTime[0]}/${returnTime[1]}/${returnTime[2]}] ${processOutput(returnTime[3])}:${processOutput(returnTime[4])}:${processOutput(returnTime[5])}`);
        } else if (returnTime.length === 2) {
            setReturnTime(`[${returnTime[0][0]}/${returnTime[0][1]}/${returnTime[0][2]}] ${processOutput(returnTime[0][3])}:${processOutput(returnTime[0][4])}:${processOutput(returnTime[0][5])} - ${processOutput(returnTime[1][3])}:${processOutput(returnTime[1][4])}:${processOutput(returnTime[1][5])}`);
        }

        setLoading(false)
        setShowResult(true)
    };

    const handleUniSpeedChange = (event) => {
        setUniSpeed(event.target.value);
    };

    const handleNumberOfGalaxiesSpeedChange = (event) => {
        setNumberOfGalaxies(event.target.value);
    };

    const handleDonutGalaxyChange = (event) => {
        setDonutGalaxy(event.target.checked);
    };

    const handleDonutSystemChange = (event) => {
        setDonutSystem(event.target.checked);
    };

    const handleTravelSpeedChange = (event) => {
        setTravelSpeed(event.target.value);
    };

    const handleFleetSpeedChange = (event) => {
        const speedValue = event.target.value;
        const cleanedValue = speedValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setFleetSpeed(cleanedValue);
    };

    const handleOriginGalaxyChange = (event) => {
        const galaxyValue = event.target.value;
        const cleanedValue = galaxyValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setOrigin([cleanedValue, origin[1], origin[2]]);
    };

    const handleOriginSystemChange = (event) => {
        const systemValue = event.target.value;
        const cleanedValue = systemValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setOrigin([origin[0], cleanedValue, origin[2]]);
    };

    const handleOriginPlanetChange = (event) => {
        const planetValue = event.target.value;
        const cleanedValue = planetValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setOrigin([origin[0], origin[1], cleanedValue]);
    };

    const handleTargetGalaxyChange = (event) => {
        const galaxyValue = event.target.value;
        const cleanedValue = galaxyValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setTarget([cleanedValue, target[1], target[2]]);
    };

    const handleTargetSystemChange = (event) => {
        const systemValue = event.target.value;
        const cleanedValue = systemValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setTarget([target[0], cleanedValue, target[2]]);
    };

    const handleTargetPlanetChange = (event) => {
        const planetValue = event.target.value;
        const cleanedValue = planetValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setTarget([target[0], target[1], cleanedValue]);
    };

    const handleSecondsBetweenScansChange = (event) => {
        const secondsValue = event.target.value;
        const cleanedValue = secondsValue.replace(/[^0-9]/g, '') || '';
        event.target.value = cleanedValue;
        setSecondsBetweenScans(cleanedValue);
    };

    const handleArrivalTimeChange = (e, index) => {
        const newArrivalTime = [...arrivalTime];
        newArrivalTime[index] = parseInt(e.target.value) || 0;
        setArrivalTime(newArrivalTime);
    };

    const handleRecallTimeChange = (e, index) => {
        const newRecallTime = [...recallTime];
        newRecallTime[index] = parseInt(e.target.value) || 0;
        setRecallTime(newRecallTime);
    };

    return (
        <div id="lanx-calculator-app">
            <Helmet>
                <title>{t("TitleLanxCalc")}</title>
            </Helmet>
            <div id="lanx-calc-panel" className="clearfix">
                <form id="lanx-calc-form">
                    <div className="container">
                        <div style={{ padding: "2%" }}>
                            
                            <span className="section-title">{t("Universe settings")}</span>
                            <hr />
                            <table className="input-table">
                                <tbody>
                                    <tr>
                                        <td className="label-cell" style={{ width: "25%" }}><label htmlFor="universeSpeed">{t("Universe Speed")}:</label></td>
                                        <td style={{ width: "25%" }}>
                                            <select id="universeSpeed" name="universeSpeed" className="select-field" defaultValue={uniSpeed} onChange={handleUniSpeedChange}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                        </td>
                                        <td className="label-cell" style={{ width: "25%" }}><label htmlFor="numOfGalaxies">{t("Number of galaxies")}:</label></td>
                                        <td style={{ width: "25%" }}>
                                            <select id="numOfGalaxies" name="numOfGalaxies" className="select-field" defaultValue={numberOfGalaxies} onChange={handleNumberOfGalaxiesSpeedChange}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="donutGalaxies">{t("Donut galaxies")}:</label></td>
                                        <td>
                                            <input type="checkbox" className="checkbox-field" value="0" checked={donutGalaxy} onChange={handleDonutGalaxyChange} />
                                        </td>
                                        <td className="label-cell"><label htmlFor="donutSystems">{t("Donut systems")}:</label></td>
                                        <td>
                                            <input type="checkbox" className="checkbox-field" value="0" checked={donutSystem} onChange={handleDonutSystemChange} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <span className="section-title">{t("Destination coordinates and arrival time")}</span>
                            <hr />
                            <table className="input-table">
                                <tbody>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="cmb-drive">{t("Arrival time")}:</label></td>
                                        <td colSpan="6">
                                            <input type="text" placeholder="dd" maxLength="2" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 0)} />
                                            <input type="text" placeholder="mm" maxLength="2" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 1)} />
                                            <input type="text" placeholder="yyyy" maxLength="4" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 2)} />
                                            <input type="text" placeholder="hh" maxLength="2" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 3)} />
                                            <input type="text" placeholder="mm" maxLength="2" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 4)} />
                                            <input type="text" placeholder="ss" maxLength="2" className={(`input-field ${isArrivalTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleArrivalTimeChange(e, 5)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="coordinates">{t("Coordinates")}:</label></td>
                                        <td colSpan="3">
                                            <input type="text" placeholder={t("Galaxy")} maxLength="1" onChange={handleOriginGalaxyChange} className={(`input-field ${isArrivalCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                            <input type="text" placeholder={t("System")} maxLength="3" onChange={handleOriginSystemChange} className={(`input-field ${isArrivalCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                            <input type="text" placeholder={t("Planet")} maxLength="2" onChange={handleOriginPlanetChange} className={(`input-field ${isArrivalCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <span className="section-title">{t("Fleet and origin coordinates")}</span>
                            <hr />
                            <table className="input-table">
                                <tbody>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="fleetCoordinates">{t("Coordinates")}:</label></td>
                                        <td colSpan="3">
                                            <input type="text" placeholder={t("Galaxy")} maxLength="1" onChange={handleTargetGalaxyChange} className={(`input-field ${isOriginCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                            <input type="text" placeholder={t("System")} maxLength="3" onChange={handleTargetSystemChange} className={(`input-field ${isOriginCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                            <input type="text" placeholder={t("Planet")} maxLength="2" onChange={handleTargetPlanetChange} className={(`input-field ${isOriginCoordinatesValid ? "" : "invalid"}`).trim()} style={{ width: "33%" }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="assumedSpeed">{t("Assumed speed")}:</label></td>
                                        <td>
                                            <select className="select-field" defaultValue={travelSpeed} onChange={handleTravelSpeedChange}>
                                                <option value="100">100%</option>
                                                <option value="90">90%</option>
                                                <option value="80">80%</option>
                                                <option value="70">70%</option>
                                                <option value="60">60%</option>
                                                <option value="50">50%</option>
                                                <option value="40">40%</option>
                                                <option value="30">30%</option>
                                                <option value="20">20%</option>
                                                <option value="10">10%</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="fleetSpeed">{t("Fleet speed")}:</label></td>
                                        <td>
                                            <input type="text" className={(`input-field ${isFleetSpeedValid ? "" : "invalid"}`).trim()} placeholder={t("Fleet speed")} value={fleetSpeed} onChange={handleFleetSpeedChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="recallServerTime">{t("Recall server time")}:</label></td>
                                        <td colSpan="6">
                                            <td colSpan="6">
                                                <input type="text" placeholder="dd" maxLength="2" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 0)} />
                                                <input type="text" placeholder="mm" maxLength="2" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 1)} />
                                                <input type="text" placeholder="yyyy" maxLength="4" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 2)} />
                                                <input type="text" placeholder="hh" maxLength="2" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 3)} />
                                                <input type="text" placeholder="mm" maxLength="2" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 4)} />
                                                <input type="text" placeholder="ss" maxLength="2" className={(`input-field ${isRecallTimeValid ? "" : "invalid"}`).trim()} style={{ width: "16.6%" }} onChange={(e) => handleRecallTimeChange(e, 5)} />
                                            </td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="secondsBetweenScans">{t("Seconds between scans")}:</label></td>
                                        <td>
                                            <input type="text" value={secondsBetweenScans} placeholder={t("Seconds between scans")} onChange={handleSecondsBetweenScansChange} className={(`input-field ${isScanDelayValid ? "" : "invalid"}`).trim()} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <span className="section-title">{t("Result")}</span>
                            <hr />
                            <table className="input-table">
                                <tbody>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="coordinates">{t("Speed")}:</label></td>
                                        <td>{showResult ? fleetSpeed : t("Not Calculated")}</td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="coordinates">{t("Distance")}:</label></td>
                                        <td>{showResult ? travelDistance : t("Not Calculated")}</td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="coordinates">{t("Travel Time")}:</label></td>
                                        <td>{showResult ? travelTime : t("Not Calculated")}</td>
                                    </tr>
                                    <tr>
                                        <td className="label-cell"><label htmlFor="coordinates">{t("Return Time")}:</label></td>
                                        <td>{showResult ? returnTime : t("Not Calculated")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className="lanx-calc-btn">
                                <Button
                                    disabled={loading}
                                    onClick={(e) => calculateReturn(e)}
                                    className="btn"
                                >
                                    <i className={`fas fa-${loading ? "spinner" : "chevron-left"}`} />
                                    {loading ? t("Loading") : t("Calculate Return")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}