import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { FormatUnits } from '../../helpers/FormatUnits';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import config from '../../config.json';
import ReactLoading from 'react-loading';

import './CombatReportDetails.css';

export default function CombatReportDetails() {
    const { t } = useTranslation();
    const history = useHistory();

    const { language, id } = useParams();
    const [selectedSkin, setSelectedSkin] = useState(localStorage.getItem("publicSkin") || "Default");
    const [optionsPanelVisible, setOptionsPanelVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reportData, setReportData] = useState({
        MetaUrl: "",
        MetaImg: "",
        AttackerNames: [],
        DefenderNames: [],
        Losses: {
            AttackerLoss: 0,
            DefenderLoss: 0,
            AttackerPos: false,
            DefenderPos: false,
        },
        Preview: '',
    });

    useEffect(() => {
        let apiUrl = config.develop ? config.develop_api : config.api;
        const request = {
            url: `${apiUrl}/reports/${id}`,
            method: 'GET',
            headers: {
                'Custom-Language': localStorage.getItem("language") || "en",
                'Custom-Skin': selectedSkin,
            },
        };

        axios(request)
            .then(function (response) {
                let data = response.data;
                data.Preview = data.Preview.replace(/\n/g, '<br/>');
                setReportData(data);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            // Handle 400 error
                            break;
                        case 404:
                            history.push('/');
                            break;
                        case 500:
                            // Handle 500 error
                            break;
                        default:
                        // Handle other errors
                    }
                } else {
                    // Handle unknown errors
                }
            });
    }, [id, t, language, selectedSkin]);

    const handleSkinChange = (event) => {
        localStorage.setItem("publicSkin", event.target.value)
        setSelectedSkin(event.target.value);
    };

    const toggleOptionsPanel = () => {
        setOptionsPanelVisible(!optionsPanelVisible);
    };

    return (
        <div>
            <Helmet>
                <title>{t("TitleBattleReport")}</title> 
            </Helmet>
            <section id="info"></section>
            <div id="page-report">
                <div>
                    <div id="report-header">
                        <h2 className="subtitle">{t("TitleBattleReport")}</h2>
                        <div className="menu clearfix">
                            <ul className="">
                                <li className="">
                                    <a className="" href={`/${language}/reports`}>☱ {t("Public Reports")}</a> 
                                </li>
                            </ul>
                        </div>
                        <div id="report-players">
                            <div className="clearfix">
                                <div id="report-attackers" className="report-players-block">
                                    <h4>{t("Attackers")}</h4>
                                    <div className={`report-players-summary report-summary-${reportData.Losses.AttackerPos ? 'positive' : 'negative'}`}>
                                        {FormatUnits(reportData.Losses.AttackerLoss)}
                                    </div>
                                    <ul>
                                        {reportData.AttackerNames.map((attackerName, index) => (
                                            <li key={index}>{attackerName}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div id="report-defenders" className="report-players-block">
                                    <h4>{t("Defenders")}</h4>
                                    <div className={`report-players-summary report-summary-${reportData.Losses.DefenderPos ? 'positive' : 'negative'}`}>
                                        {FormatUnits(reportData.Losses.DefenderLoss)}
                                    </div>
                                    <ul>
                                        {reportData.DefenderNames.map((defenderName, index) => (
                                            <li key={index}>{defenderName}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div id="report-versus" className="subtitle">
                                    VS
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`options-panel ${optionsPanelVisible ? 'expanded' : ''}`}>
                        <div className="options-panel-header" onClick={toggleOptionsPanel}>
                            {optionsPanelVisible ? (
                                <>
                                    <span className="options-title">{t('Settings')}</span>
                                    <span className="options-panel-icon">▼</span>
                                </>
                            ) : (
                                <>
                                    <span className="options-title">{t('Settings')}</span>
                                    <span className="options-panel-icon">▶</span>
                                </>
                            )}
                        </div>
                        <div className="options-panel-content">
                            {optionsPanelVisible && (
                                <div id="cr-options">
                                    <>
                                        {/* Skin selection dropdown */}
                                        <label htmlFor="skinSelect">{t("Report Layout")}: </label>
                                        <select
                                            id="skinSelect"
                                            value={selectedSkin}
                                            onChange={handleSkinChange}
                                        >
                                            <option value="Default">Default</option>
                                            <option value="OGotcha">OGotcha</option>
                                            <option value="TopRaider">TopRaider</option>
                                        </select>
                                    </>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="report-content-wrapper">
                        <div id="report-content">
                            <pre>
                                {
                                    loading ? <ReactLoading type="spinningBubbles" className="react-loader" /> : ReactHtmlParser(reportData.Preview)
                                }
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}