import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from '../../TextInput/TextInput';
import Button from '../../Button/Button';
import "./ReportKeyForm.css";

export default function ReportKeyForm({ loading, apiKeyInput, apiKeyInputValidity, apiKeyInputValidityMessage, setApiKeyInput, setShowCustom, setShowSettings, fetchReport, resetReports }) {
    const { t } = useTranslation();

    return (
        <div>
            <div className="api-keys-input">
                <TextInput
                    placeholder={t("ApiKeysInputPlaceholder")}
                    value={apiKeyInput}
                    onChange={(event) => setApiKeyInput(event.target.value)}
                    valid={apiKeyInputValidity}
                    disabled={loading}
                />
                {apiKeyInputValidityMessage !== "" ?
                <div className="api-key-validity-message">
                    {t(apiKeyInputValidityMessage)}
                </div>
                : ""}
                <Button
                    onClick={fetchReport}
                    disabled={loading}>
                    <i className={`fas fa-${loading ? "spinner" : "plus"}`} />
                    {loading ? t("Loading") : t("AddKey")}
                </Button>
                <Button
                    onClick={() => setShowCustom(true)}
                    disabled={loading}>
                    <i className={`fas fa-${loading ? "spinner" : "plus"}`} />
                    {t("AddManual")}
                </Button>
                <Button
                    onClick={() => setShowSettings(true)}
                    disabled={loading}>
                    <i className="fas fa-cogs" />
                    {t("Settings")}
                </Button>
                <Button
                    onClick={resetReports}
                    disabled={loading}>
                    <i className="fas fa-trash-alt" />
                    {t("Reset")}
                </Button>
            </div>
        </div>
    )
}