import axios from 'axios';
import config from '../../config.json';

export const PatchSharedReport = (sharedReportId, data, setShareLoading) => {
    let apiUrl = config.develop ? config.develop_api : config.api;
    const request = {
        method: "PATCH",
        url: `${apiUrl}/share-acs-report/${sharedReportId}`,
        data: data
    };
    setShareLoading(true);
    axios(request)
        .then(function (response) {
            setShareLoading(false);
        })
        .catch(function (error) {
            setShareLoading(false);
            console.log(error);
            if (error.response)
                switch (error.response.status) {
                    case 400:
                        console.log("InvalidKey");
                        break;
                    case 500:
                        console.log("ServerError");
                        break;
                    default:
                        console.log("UnknownError");
                }
            else
                console.log("UnknownError");
        })
}