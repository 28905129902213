import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config.json';
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import "./AcsReportAccess.css";

export default function AcsReportAccess({ sharedReportId, setIsAllowedToAccessSharedReport, setDataFromSharedReport }) {
    const { t } = useTranslation();
    const history = useHistory();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false)
    const [validPinCodeInput, setValidPinCodeInput] = useState(true)
    const [validPinCodeInputMessage, setValidPinCodeInputMessage] = useState("")

    const handleLogin = () => {
        setLoading(true)
        setValidPinCodeInput(true);
        setValidPinCodeInputMessage("")

        if (password === "") {
            setLoading(false);
            setValidPinCodeInput(false);
            setValidPinCodeInputMessage("PincodeRequired")
            return;
        }

        let apiUrl = config.develop ? config.develop_api : config.api;
        let formData = new FormData();
        formData.append("reportID", sharedReportId);
        formData.append("playerName", username);
        formData.append("pinCode", password);


        const request = {
            url: `${apiUrl}/protected-acs-access`,
            method: 'POST',
            data: formData,
        };

        axios(request)
            .then(function (response) {
                setLoading(false)
                setIsAllowedToAccessSharedReport(true);
                const acsData = localStorage.getItem('acs_me');
                const acsArray = acsData ? JSON.parse(acsData) : [];
                const newEntry = { id: sharedReportId, name: username };
                const isIdFound = acsArray.some(item => item.id === newEntry.id);
                if (!isIdFound) {
                    acsArray.push(newEntry);
                    localStorage.setItem('acs_me', JSON.stringify(acsArray));
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            // Handle 400 error
                            break;
                        case 401:
                            setValidPinCodeInput(false)
                            setValidPinCodeInputMessage("InvalidPincode")
                            break;
                        case 500:
                            setValidPinCodeInputMessage("ServerError");
                            break;
                        default:
                            setValidPinCodeInputMessage("UnknownError");
                    }
                } else {
                    // Handle unknown errors
                }
            });
        setLoading(false)
    };

    useEffect(() => {
        let apiUrl = config.develop ? config.develop_api : config.api;
        const request = {
            url: `${apiUrl}/fetch-shared-acs-report/${sharedReportId}`,
            method: 'GET',
        };

        setLoading(true)
        axios(request)
            .then(function (response) {
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false);
                if (error.response)
                    switch (error.response.status) {
                        case 404:
                            setDataFromSharedReport(false)
                            let language = localStorage.getItem("language") || "en";
                            let url = '/' + language + '/acs-splitter';
                            history.push(url);
                            break;
                        case 500:
                            console.log("ServerError");
                            break;
                        default:
                            console.log("UnknownError");
                    }
                else
                    console.log("UnknownError");
            })
    }, [])

    return (
        <div className="acs-protected-access-container">
            <div className="card mb-0">
                <div className="card-body">
                    <div className="text-center mb-3">
                        <h5 className="mb-0">{t("RestrictedAccess")}</h5>
                        <span className="d-block text-muted">{t("Enter access credentials below")}</span>
                        {!validPinCodeInput ?
                            <div class="alert alert-danger" role="alert">
                                {t(validPinCodeInputMessage)}
                            </div> : ""}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t("PlayerName")}</label>
                        <div className="form-control-feedback form-control-feedback-start">
                            <TextInput
                                className="form-control"
                                placeholder="Legor"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t("PinCode")}</label>
                        <div className="form-control-feedback form-control-feedback-start">
                            <TextInput
                                type="password"
                                className="form-control"
                                placeholder={t("PinCode")}
                                onChange={(e) => setPassword(e.target.value)}
                                valid={validPinCodeInput}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <Button
                            disabled={loading}
                            onClick={() => handleLogin(1)}
                            className="btn btn-primary w-100"
                        >
                            <i className={`fas fa-${loading ? "spinner" : "chevron-left"}`} />
                            {loading ? t("Loading") : t("JoinAcs")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}