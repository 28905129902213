import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import CombatConverter from './components/CombatConverter/CombatConverter';
import CombatReportDetails from './components/CombatReportDetails/CombatReportDetails';
import CombatReports from './components/CombatReports/CombatReports';
import DiscordComponent from './components/DiscordComponent/DiscordComponent';
import AcsSplit from './components/AcsSplit/AcsSplit';
import LanxCalculator from './components/LanxCalculator/LanxCalculator';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import config from './config.json';

import './App.css';

function App() {
  const { t } = useTranslation();
  const [activeRoute, setActiveRoute] = useState('');
  const developModeAllowed = localStorage.getItem("developMode") || false

  return (
    <HelmetProvider>
      <Router>
        <div className="app">
          <Header />
          <main className={`${activeRoute === 'discord' ? 'discord-active' : ''}`}>
            {config.maintenance && !developModeAllowed ?
              <NotFound
                code="401"
                message={t("UnderMaintenance")}
              /> :
              <Switch>
                <Route exact path="/:language/report/:id" component={CombatReportDetails} />
                <Route exact path="/:language/reports" component={CombatReports} />
                <Route exact path="/:language/acs-splitter/:id" component={AcsSplit} />
                <Route exact path="/:language/discord-invite" render={({ match }) => {
                  if (match) {
                    setActiveRoute('discord');
                  } else {
                    setActiveRoute('');
                  }
                  return <DiscordComponent />;
                }} />
                <Route exact path="/:language/acs-splitter" component={AcsSplit} />
                <Route exact path="/:language/lanx-calc" component={LanxCalculator} />
                <Route exact path="/:language/contact-us" component={ContactUs} />
                <Route exact path="/:language" component={CombatConverter} />
                <Route exact path="/" component={CombatConverter} />
                <Route component={NotFound} />
              </Switch>
            }
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App; 
