import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import TextInput from '../../../../components/TextInput/TextInput';
import SectionTitle from '../../../../components/SectionTitle/SectionTitle';
import { SetResourceStatistics } from '../../SetResourceStatistics';

import './ACSAttackResults.css';

export default function ACSAttackResults({ combatReports, setCombatReports, settingsData, side }) {
    /*Nightmare loves Tanavast.*/

    const { t } = useTranslation();
    const [AttackResultsRows, setAttackResultsRows] = useState([]);
    const [DataVisible, setDataVisible] = useState(true)

    const onResourceChange = (event, index, resourceType) => {
        let newCRs = [...combatReports];
        let value = parseInt(event.target.value);
        value = isNaN(value) ? 0 : value;
        switch (resourceType) {
            case 0: newCRs[index].metalLoot = value; break;
            case 1: newCRs[index].crystalLoot = value; break;
            case 2: newCRs[index].deuteriumLoot = value; break;
            case 3: newCRs[index].debrisMetal = value; break;
            case 4: newCRs[index].debrisCrystal = value; break;
            case 5: newCRs[index].debrisDeuterium = value; break;
            case 6: newCRs[index].debrisReaperMetal = value; break;
            case 7: newCRs[index].debrisReaperCrystal = value; break;
            case 8: newCRs[index].debrisReaperDeuterium = value; break;
            default: break;
        }

        SetResourceStatistics(newCRs, settingsData);
        setCombatReports(newCRs);
    }

    useEffect(() => {
        let attackResultsRows = []

        combatReports.forEach((report, index) => {
            attackResultsRows.push((
                <div
                    className="acs-report-details"
                    key={`ACSATTACKRESULT${report.id}`}
                >
                    <div className="card">
                        <div className="label mt-5">{t(side === 0 ? "Attack" : "Defend")} #{index + 1}</div>

                        <div className="label mt-5">{t("Loot")}</div>
                        <div className="input-row mt-5">
                            {t("Metal")}
                            <TextInput
                                value={report.metalLoot}
                                onChange={event => onResourceChange(event, index, 0)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("Crystal")}
                            <TextInput
                                value={report.crystalLoot}
                                onChange={event => onResourceChange(event, index, 1)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("Deuterium")}
                            <TextInput
                                value={report.deuteriumLoot}
                                onChange={event => onResourceChange(event, index, 2)}
                            />
                        </div>

                        <div className="label mt-5">{t("DebrisField")}</div>
                        <div className="input-row mt-5">
                            {t("DFMetal")}
                            <TextInput
                                value={report.debrisMetal}
                                onChange={event => onResourceChange(event, index, 3)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("DFCrystal")}
                            <TextInput
                                value={report.debrisCrystal}
                                onChange={event => onResourceChange(event, index, 4)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("DFDeuterium")}
                            <TextInput
                                value={report.debrisDeuterium}
                                onChange={event => onResourceChange(event, index, 5)}
                            />
                        </div>

                        <div className="label mt-5">{t("ReaperFields")}</div>
                        <div className="input-row mt-5">
                            {t("DFReaperMetal")}
                            <TextInput
                                value={report.debrisReaperMetal}
                                onChange={event => onResourceChange(event, index, 6)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("DFReaperCrystal")}
                            <TextInput
                                value={report.debrisReaperCrystal}
                                onChange={event => onResourceChange(event, index, 7)}
                            />
                        </div>
                        <div className="input-row mt-5">
                            {t("DFReaperDeuterium")}
                            <TextInput
                                value={report.debrisReaperDeuterium}
                                onChange={event => onResourceChange(event, index, 8)}
                            />
                        </div>
                    </div>
                </div>
            ))

        })

        setAttackResultsRows(attackResultsRows);
    }, [combatReports, t])

    return (
        <div className="acs-attack-results-main">
            <SectionTitle
                title={t("ACS Attack Results")}
                icon={DataVisible ? "caret-up" : "caret-down"}
                onClick={() => setDataVisible(!DataVisible)}
            />
            <div
                className="acs-attack-results"
                style={{ display: DataVisible && AttackResultsRows.length > 0 ? "flex" : "none" }}
            >
                <div className="acs-attack-icon">
                    <i className="fas fa-trophy" />
                </div>
                <div className="acs-attack-details-panel">
                    {AttackResultsRows}
                </div>
            </div>
        </div>

    )
}