import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import EventSource from 'eventsource';
import SelectPlayerSide from './SelectPlayerSide/SelectPlayerSide';
import AcsReportAccess from './AcsReportAccess/AcsReportAccess';
import ReportKeyForm from './ReportKeyForm/ReportKeyForm';
import CustomReport from './CustomReport/CustomReport';
import Settings from './Settings/Settings';
import Panel from './Panel/Panel';
import { FetchCombatReport } from './FetchCombatReport';
import { FetchRecycleReport } from './FetchRecycleReport';
import { PostSharedReport } from './PostSharedReport';
import { PatchSharedReport } from './PatchSharedReport';
import { GetSharedReport } from './GetSharedReport';
import { Helmet } from 'react-helmet-async';
import config from '../../config.json';

import "./AcsSplit.css";

export default function AcsSplit() {
    const [ShowSettings, setShowSettings] = useState(false)
    const [SettingsData, setSettingsData] = useState({
        reimburseFleetLoss: true,
        reimburseDeutConsumption: true,
        weightedCut: false,
        convertConsumption: false,
        conversationRate: { metal: "3", crystal: "2" },
        probeStorage: false
    })

    const { t } = useTranslation();

    const { id } = useParams();

    const [Side, setSide] = useState(-1);
    const [SharedReportId, setSharedReportId] = useState(0);
    const [SharedReportPinCode, setSharedReportPinCode] = useState(0);

    const [ApiKeyInput, setApiKeyInput] = useState("");
    const [ApiKeyInputValidity, setApiKeyInputValidity] = useState(true);
    const [ApiKeyInputValidityMessage, setApiKeyInputValidityMessage] = useState("");

    const [ApiKeyList, setApiKeyList] = useState([])
    const [CombatReports, setCombatReports] = useState([]);
    const [RecycleReports, setRecycleReports] = useState([]);
    const [PlayerTotalsStatistics, setPlayerTotalsStatistics] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [ShowCustom, setShowCustom] = useState(false);
    const [ShareLoading, setShareLoading] = useState(false);
    const [DataFromSharedReport, setDataFromSharedReport] = useState(false);
    const [IsAllowedToAccessSharedReport, setIsAllowedToAccessSharedReport] = useState(false);
    const [acsWhoAmI, setAcsWhoAmI] = useState("")

    useEffect(() => {
        setLoading(false);
        setApiKeyInput("");
        let reports = [];
        CombatReports.forEach((rep, index) => {
            reports.push((
                <div
                    key={`CRLIST${index}`}
                    className="api-key-list-item"
                >

                    <i
                        className="fas fa-times-circle"
                        onClick={() => deleteCombatReport(index)}
                    />
                    {`${t(Side === 0 ? "Attack" : "Defend")} #${index + 1} [${rep.id}] `}
                </div>
            ))
        })

        RecycleReports.forEach((rep, index) => {
            reports.push((
                <div
                    key={`RRLIST${index}`}
                    className="api-key-list-item"
                >

                    <i
                        className="fas fa-times-circle"
                        onClick={() => deleteRecycleReport(index)}
                    />
                    {`${t("Harvest")} #${index + 1} [${rep.id}] `}
                </div>
            ))
        })
        setApiKeyList(reports);
    }, [CombatReports, RecycleReports, t])

    const shareSplitReport = () => {
        let data = {
            combatReports: CombatReports,
            recycleReports: RecycleReports,
            side: Side,
            settings: SettingsData,
            playersStatistics: PlayerTotalsStatistics
        }
        PostSharedReport(data, setSharedReportId, setSharedReportPinCode, setApiKeyInputValidity, setShareLoading, setApiKeyInputValidityMessage);
    }

    const updateSplitReport = () => {
        let data = {
            combatReports: CombatReports,
            recycleReports: RecycleReports,
            settings: SettingsData,
            playersStatistics: PlayerTotalsStatistics
        }
        PatchSharedReport(SharedReportId, data, setShareLoading);
    }

    useEffect(() => {
        if (IsAllowedToAccessSharedReport) {
            getAcsWhoAmI(SharedReportId);
            GetSharedReport(SharedReportId, setShareLoading, setApiKeyInputValidity, setApiKeyInputValidityMessage, setCombatReports, setRecycleReports, setSide, setPlayerTotalsStatistics, setSettingsData);
            openSSEConnection(SharedReportId);
        }
    }, [IsAllowedToAccessSharedReport])

    useEffect(() => {
        if (id) {
            setShareLoading(true)
            setDataFromSharedReport(true);
            setSharedReportId(id);

            const acsData = localStorage.getItem('acs_me');
            const acsArray = acsData ? JSON.parse(acsData) : [];
            const isIdFound = acsArray.some(item => item.id === id);

            setIsAllowedToAccessSharedReport(isIdFound);
            setShareLoading(false)
        }
    }, [id])

    const getAcsWhoAmI = (id) => {
        const acsData = localStorage.getItem('acs_me');
        const acsArray = acsData ? JSON.parse(acsData) : [];

        const matchingEntry = acsArray.find((item) => item.id === id);

        if (matchingEntry) {
            setAcsWhoAmI(matchingEntry.name);
        }
    };

    function openSSEConnection(id) {
        let apiUrl = config.develop ? config.develop_api : config.api;
        const eventSource = new EventSource(apiUrl + '/sse/' + id); // Replace with your actual SSE endpoint URL

        // Handle SSE events
        eventSource.onmessage = (event) => {
            // Handle incoming SSE data here
            const eventData = JSON.parse(event.data);
            console.log('Received SSE event:', eventData);
        };

        // Handle SSE errors
        eventSource.onerror = (error) => {
            console.error('SSE error:', error);
            eventSource.close();
        };
    }

    const fetchReport = () => {
        let keyArray = ApiKeyInput.trim().split('-');
        if (keyArray.length === 4) {
            switch (keyArray[0]) {
                case "cr":
                    FetchCombatReport(ApiKeyInput.trim(), CombatReports, setCombatReports, setApiKeyInputValidity, setLoading, setApiKeyInputValidityMessage, SettingsData, setSettingsData);
                    break;
                case "rr":
                    FetchRecycleReport(ApiKeyInput.trim(), RecycleReports, setRecycleReports, setApiKeyInputValidity, setLoading, setApiKeyInputValidityMessage);
                    break;
                default:
                    setApiKeyInputValidity(false);
                    setApiKeyInputValidityMessage("InvalidKey");
                    break;
            }
        } else {
            setApiKeyInputValidity(false);
            setApiKeyInputValidityMessage("InvalidKey");
        }
    }

    const resetReports = () => {
        setCombatReports([]);
        setRecycleReports([]);
        setApiKeyInputValidity(true);
        setApiKeyInputValidityMessage("");
        setSide(-1);
        setSharedReportId(0);
        setSharedReportPinCode(0);
        setDataFromSharedReport(false);
    }

    const deleteCombatReport = index => {
        let combatReports = [...CombatReports];
        combatReports.splice(index, 1);
        setCombatReports(combatReports);
    }

    const deleteRecycleReport = index => {
        let recycleReports = [...RecycleReports];
        recycleReports.splice(index, 1);
        setRecycleReports(recycleReports);
    }

    if (Side === -1 && ShareLoading)
        return (<div className="loading-main"><i className="fas fa-spinner" /></div>)

    if (DataFromSharedReport && !IsAllowedToAccessSharedReport) {
        return (
            <div className="acs-splitter-app">
                <Helmet>
                    <title>{t("TitleAcsSplitter")}</title>
                </Helmet>
                <AcsReportAccess
                    sharedReportId={SharedReportId}
                    setIsAllowedToAccessSharedReport={setIsAllowedToAccessSharedReport}
                    setDataFromSharedReport={setDataFromSharedReport}
                />
            </div>
        );
    }

    if (Side === -1)
        return (
            <div className="acs-splitter-app">
                <Helmet>
                    <title>{t("TitleAcsSplitter")}</title>
                </Helmet>
                <SelectPlayerSide setSide={setSide} />
            </div>
        );

    return (
        <div className="acs-splitter-app">
            <Helmet>
                <title>{t("TitleAcsSplitter")}</title>
            </Helmet>
            <ReportKeyForm
                loading={Loading}
                apiKeyInput={ApiKeyInput}
                apiKeyInputValidity={ApiKeyInputValidity}
                apiKeyInputValidityMessage={ApiKeyInputValidityMessage}
                setApiKeyInput={setApiKeyInput}
                setShowCustom={setShowCustom}
                setShowSettings={setShowSettings}
                fetchReport={fetchReport}
                resetReports={resetReports}
            />
            {ShowCustom ?
                <CustomReport
                    combatReports={CombatReports}
                    setCombatReports={setCombatReports}
                    recycleReports={RecycleReports}
                    setRecycleReports={setRecycleReports}
                    side={Side}
                    setShowCustom={setShowCustom}
                    settingsData={SettingsData}
                /> : ""}
            {ApiKeyList}
            <Panel
                combatReports={CombatReports}
                setCombatReports={setCombatReports}
                recycleReports={RecycleReports}
                setRecycleReports={setRecycleReports}
                playerTotalsStatistics={PlayerTotalsStatistics}
                setPlayerTotalsStatistics={setPlayerTotalsStatistics}
                settingsData={SettingsData}
                side={Side}
                dataFromSharedReport={DataFromSharedReport}
                acsWhoAmI={acsWhoAmI}
            />
            <div className="share-container">
                <div className="share">
                    {SharedReportId === 0 && (CombatReports.length > 0 || RecycleReports.length > 0) ?

                        <div
                            className={`share-button ${SharedReportId !== 0 ? "small" : ""}`}
                            onClick={shareSplitReport}
                        >
                            <i className={`fas fa-${ShareLoading ? "spinner" : "share-alt"}`} />
                            <div class="title">{SharedReportId === 0 ? t("Share") : ""}</div>
                        </div> : ""}
                    {SharedReportId !== 0 && !DataFromSharedReport && (CombatReports.length > 0 || RecycleReports.length > 0) ?
                        <div className="share-content">
                            <div className="share-link">{config.develop ? config.develop_app : config.app}{localStorage.getItem("language") || "en"}{"/acs-splitter/"}{SharedReportId}</div>
                            <div className="pincode">Pin Code: {SharedReportPinCode}</div>
                            <div className="copy-icon">
                                <i className="fas fa-copy"></i>
                            </div>
                        </div> : ""}
                </div>
            </div>
            {SharedReportId !== 0 && DataFromSharedReport && IsAllowedToAccessSharedReport && !ShareLoading && (CombatReports.length > 0 || RecycleReports.length > 0) ?
                <div className="floating-button-container">
                    <button className="floating-button" onClick={updateSplitReport}>
                        <i className="fas fa-save"></i>
                    </button>
                </div> : ""}
            {ShowSettings ?
                <Settings
                    settingsData={SettingsData}
                    setSettingsData={setSettingsData}
                    setShowSettings={setShowSettings} /> : ""}
        </div>
    )
}