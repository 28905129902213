import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import Popup from '../Popup/Popup';
import ReactAutoCompleteTagsInput from "../ReactAutoCompleteTagsInput/ReactAutoCompleteTagsInput"
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import config from '../../config.json';

import "./CombatConverter.css";

export default function CombatConverter() {
    const { t } = useTranslation();
    const [Loading, setLoading] = useState(false);

    const [formData, setFormData] = useState(new FormData());
    const [inputIndices, setInputIndices] = useState({});
    const [apiData, setApiData] = useState(null);
    const [ApiKeyInputValidity, setApiKeyInputValidity] = useState(true);
    const [ApiKeyInput, setApiKeyInput] = useState("");
    const [ApiKeyInputValidityMessage, setApiKeyInputValidityMessage] = useState("");
    const language = localStorage.getItem('language');
    const [saveOptions, setSaveOptions] = useState(localStorage.getItem('battleConverterData') !== null);
    const [discordSuggestions, setDiscordSuggestions] = useState(JSON.parse(localStorage.getItem('discordSuggestions')) || []);
    const [SettingsData, setSettingsData] = useState(JSON.parse(localStorage.getItem('battleConverterData')) || {
        options: {
            optionAdvanced: true,
            optionHideTime: true,
            optionMergeFleet: true,
            optionAlliance: true,
            optionHonour: false,
            optionMoonchance: true,
            optionCombattech: false,
            optionDebrisDetails: false,
            //optionWreckfield: false,
        },
        discordServers: [],
        skin: 'Default',
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSettingsData((prevSettingsData) => {
            return {
                ...prevSettingsData,
                options: {
                    ...prevSettingsData.options,
                    [name]: checked
                }
            };
        });
    };

    const handleSaveOptionsChange = (event) => {
        setSaveOptions(event.target.checked);
    };

    const handleSkinChange = (event) => {
        const skinValue = event.target.value;
        setSettingsData((prevSettingsData) => ({
            ...prevSettingsData,
            skin: skinValue
        }));
    };

    const saveOptionsToLocalStorage = () => {
        if (saveOptions) {
            localStorage.setItem('battleConverterData', JSON.stringify(SettingsData));
        }
        else {
            localStorage.removeItem('battleConverterData');
        }
    };

    useEffect(() => {
        saveOptionsToLocalStorage();
    }, [SettingsData, saveOptions]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const queryParamValue = params.get('CR_KEY');
        setApiKeyInput(queryParamValue)
    }, []);

    const handleFormSubmit = (event) => {
        setLoading(true);

        event.preventDefault();
        const form = document.getElementById('form');

        for (let i = 0; i < form.elements.length; i++) {
            const element = form.elements[i];

            if (element.name) {
                if (element.type === 'checkbox') {
                    formData.append(element.name, element.checked);
                } else if (element.type === 'select-one') {
                    formData.append(element.name, element.value);
                } else if (element.type === 'select-multiple') {
                    for (let j = 0; j < element.options.length; j++) {
                        if (element.options[j].selected) {
                            formData.append(element.name, element.options[j].value);
                        }
                    }
                } else {
                    formData.append(element.name, element.value.trim());
                }
            }
        }

        const secretsArray = SettingsData.discordServers.map(server => server.Secret);
        secretsArray.forEach(secret => {
            formData.append('discord_share[]', secret);
        });

        let apiUrl = config.develop ? config.develop_api : config.api;
        const request = {
            url: `${apiUrl}/convert-combat-report`,
            method: "POST",
            data: formData,
            headers: {
                'Custom-Language': localStorage.getItem("language") || "en",
            },
        };

        axios(request)
            .then(function (response) {
                if (response.data.errors && response.data.errors.length > 0) {
                    setApiKeyInputValidity(false);
                    setApiKeyInputValidityMessage(response.data.errors.join(', '));
                    setLoading(false);
                } else {
                    let data = response.data;
                    setApiData(data);
                    setApiKeyInputValidity(true);
                    setApiKeyInputValidityMessage("");
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setApiKeyInputValidity(false);
                if (error.response)
                    switch (error.response.status) {
                        case 400:
                            setApiKeyInputValidityMessage(error.response.data);
                            break;
                        case 500:
                            setApiKeyInputValidityMessage("ServerError");
                            break;
                        default:
                            setApiKeyInputValidityMessage("UnknownError");
                    }
                else {
                    setApiKeyInputValidityMessage("UnknownError");
                }
            })

        setFormData(new FormData());
    };

    const handleAddInput = (containerId) => {
        const index = inputIndices[containerId] || 0;
        const container = document.getElementById(containerId);

        if (container) {
            const inputField = container.querySelector('input');
            const newInputField = inputField.cloneNode(true);
            const newIndex = index + 1;

            newInputField.value = ''; // Clear the value of the cloned input
            newInputField.style.border = '1px solid transparent';

            newInputField.addEventListener('input', (e) => handleInputChange(e, containerId));

            // Update the state with the new index
            setInputIndices({ ...inputIndices, [containerId]: newIndex });

            // Insert the cloned input field after the latest input in the container
            container.insertBefore(newInputField, container.querySelector('.cr-option-add')); // Insert at the end
        }
    };

    const containerRules = {
        "cr-attacker-df": /^rr-[a-z]{2}-\d{1,3}-\w{40}$/,
        "cr-defender-df": /^rr-[a-z]{2}-\d{1,3}-\w{40}$/,
        "cr-options-raids": /^cr-[a-z]{2}-\d{1,3}-\w{40}$/,
        "cr-options-missiles": /^mr-[a-z]{2}-\d{1,3}-\w{40}$/,
        "cr-attacker-deut": /^\d{1,11}$/,
        "cr-defender-deut": /^\d{1,11}$/,
    };

    const handleInputChange = (e, containerId) => {
        const container = document.getElementById(containerId);
        const inputValue = e.target.value;
        const rule = containerRules[containerId];

        if (container) {
            const inputField = e.target;
            const otherEmptyInputs = Array.from(container.querySelectorAll('input')).filter(input => input.value.trim() === '' && input !== inputField);
            const validValuesExist = Array.from(container.querySelectorAll('input')).some(input => {
                const value = input.value.trim();
                return value !== '' && rule && value.match(rule);
            });

            if (rule && inputValue.match(rule)) {
                inputField.style.border = '1px solid transparent';
                if (otherEmptyInputs.length === 0) {
                    handleAddInput(containerId);
                }
            } else if (inputValue.trim() === '') {
                inputField.style.border = '1px solid transparent';
            } else {
                inputField.style.border = '1px solid red';
            }

            // Check if the containerId is 'cr-attacker-df' or 'cr-defender-df' and if there is at least one valid value
            if ((containerId === 'cr-attacker-df' || containerId === 'cr-defender-df') && validValuesExist) {
                const selectBox = document.getElementById('cr-gotdebris-select');
                if (selectBox) {
                    selectBox.value = 'api';
                }
            }
        }
    };

    const addItemToToolsList = (item) => {
        const isItemAlreadyPresent = discordSuggestions.some(existingItem => existingItem.ServerName === item.ServerName);

        if (!isItemAlreadyPresent) {
            const updatedItems = [...discordSuggestions, item];
            localStorage.setItem('discordSuggestions', JSON.stringify(updatedItems));
            setDiscordSuggestions(updatedItems)
        }

        setSettingsData((prevSettingsData) => ({
            ...prevSettingsData,
            discordServers: [...SettingsData.discordServers, item]
        }));
    }


    const deleteItemFromToolsList = (event, itemToRemove) => {
        event.preventDefault();

        setSettingsData((prevSettingsData) => ({
            ...prevSettingsData,
            discordServers: SettingsData.discordServers.filter((item) => item.ServerName !== itemToRemove)
        }));
    }

    const filterToolsOptionsHandler = (input) => {
        let options = SettingsData.discordServers || [];

        if (!input || input.length === 0) {
            return discordSuggestions;
        }

        // Filter out suggestions that are already present in SettingsData.discordServers
        const filteredSuggestions = discordSuggestions.filter(suggestion =>
            !options.some(item => item.ServerName.toLowerCase() === suggestion.ServerName.toLowerCase())
        );

        return filteredSuggestions.filter(function (item) {
            // Filter based on ServerName property
            return item.ServerName.toLowerCase().includes(input.toLowerCase());
        });
    }

    const onValidateNewItem = async (item) => {
        try {
            let apiUrl = config.develop ? config.develop_api : config.api;
            const request = {
                url: `${apiUrl}/validate-discord-tag`,
                method: "POST",
                data: { item: item.Secret },
            };

            const response = await axios(request);
            if (response.data.errors && response.data.errors.length > 0) {
                console.log(response.data.errors);
                return false;
            } else {
                let data = response.data;
                return data;
            }
        } catch (error) {
            console.error("Error validating item:", error);

            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        const indexToDelete = discordSuggestions.findIndex(existingItem => existingItem.ServerName === item.ServerName);
                        if (indexToDelete !== -1) {
                            const updatedItems = [...discordSuggestions];
                            updatedItems.splice(indexToDelete, 1);
                            localStorage.setItem('discordSuggestions', JSON.stringify(updatedItems));
                            setDiscordSuggestions(updatedItems);
                        }
                        break;
                    default:
                        break
                }
            }
            
            return false;
        }
    };

    return (
        <div className="cr-converter-app">
            <Helmet>
                <title>{t("TitleConverter")}</title>
            </Helmet>
            {apiData && <Popup res={apiData} setLoading={setLoading} />}
            <div className="cr-input-label clearfix">
                <span>⇃</span>{ReactHtmlParser(t("Paste your CR-KEY"))}
                <div className="menu clearfix">
                    <ul className="">
                        <li className="">
                            <a className="" href={`/${language}/discord-invite`}><svg xmlns="http://www.w3.org/2000/svg" width="16" fill="#f1f1f1" viewBox="0 0 640 512"><path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" /></svg> {t("OGameTools Bot")}</a>
                        </li>
                        <li className="">
                            <a className="" href={`/${language}/reports`}>☱ {t("Public Reports")}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="cr-form" className="clearfix">
                <form id="form">
                    <div id="input-wrapper">
                        <TextInput
                            placeholder={t("Enter combat report ID")}
                            disabled={Loading}
                            name="cr_key_id"
                            valid={ApiKeyInputValidity}
                            value={ApiKeyInput}
                        />
                    </div>
                    {ApiKeyInputValidityMessage !== "" ?
                        <div className="api-key-error-message">
                            {t(ApiKeyInputValidityMessage)}
                        </div>
                        : ""}
                    <div id="cr-options">
                        <div id="cr-options-top-container" className="clearfix">
                            <div id="cr-gotdebris" className="cr-options-box">
                                <label htmlFor="cr-gotdebris-select">{t("Who recycled the debris field")}</label>
                                <select id="cr-gotdebris-select" name="option_gotdebris" className="save-option" defaultValue={t("Attacker(s)")}>
                                    <option value="attacker" id="option-gotdebris-0">{t("Attacker(s)")}</option>
                                    <option value="defender" id="option-gotdebris-1">{t("Defender(s)")}</option>
                                    <option value="nobody" id="option-gotdebris-2">{t("Nobody")}</option>
                                    <option value="api" id="option-gotdebris-3">{t("Use debris API keys")}</option>
                                </select>
                            </div>
                            <div id="cr-report-skin" className="cr-options-box">
                                <label htmlFor="cr-skin-select">{t("Report Layout")} :</label>
                                <select id="cr-skin-select" name="report_skin" className="save-option" defaultValue={SettingsData.skin} onChange={handleSkinChange}>
                                    <option id="option-skin-default">Default</option>
                                    <option id="option-skin-ogotcha">OGotcha</option>
                                    <option id="option-skin-topraider">TopRaider</option>
                                </select>
                            </div>
                            <div id="cr-report-title" className="cr-options-box">
                                <label htmlFor="cr-report-title-field" className="clearfix">Share on Discord <small><a style={{ color: "#fc0" }} href={`/${language}/discord-invite`}>(INFO)</a></small> :</label>
                                <ReactAutoCompleteTagsInput
                                    items={SettingsData.discordServers}
                                    addItemHandler={addItemToToolsList}
                                    deleteItemHandler={deleteItemFromToolsList}
                                    filterOptionsHandler={filterToolsOptionsHandler}
                                    validateHandler={onValidateNewItem}
                                />
                            </div>
                        </div>
                        <div id="cr-options-container" className="cr-options-container clearfix">
                            <div id="cr-options-checkboxes" className="cr-options-box">
                                <h4>{t("Options")}</h4>
                                {Object.keys(SettingsData.options).map((option) => (
                                    <label key={option}>
                                        {t(option)}:{' '}
                                        <input
                                            type="checkbox"
                                            className="save-option"
                                            name={option}
                                            checked={SettingsData.options[option]}
                                            onChange={handleCheckboxChange}
                                        />
                                    </label>
                                ))}
                            </div>
                            <div className="cr-options-player cr-options-box">
                                <h4>{t("Attacker(s)")}</h4>
                                <div id="cr-attacker-df">
                                    <label>{t("Harvest reports")}</label>
                                    <input type="text" name="attacker_df[]" placeholder={t("Harvest report key")} pattern="rr-[a-z]{2}-\d{1,3}-\w{40}" onChange={(e) => handleInputChange(e, 'cr-attacker-df')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-att-df-add" className="cr-option-add" data-container-id="cr-attacker-df" onClick={(e) => { e.preventDefault(); handleAddInput('cr-attacker-df') }}>{t("Add More")}</a>
                                </div>
                                <div id="cr-options-missiles">
                                    <label>{t("Missile reports")}</label>
                                    <input type="text" name="missiles[]" placeholder="Missile key" pattern="mr-[a-z]{2}-\d{1,3}-\w{40}" onChange={(e) => handleInputChange(e, 'cr-options-missiles')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-missiles-add" className="cr-option-add" data-container-id="cr-options-missiles" onClick={(e) => { e.preventDefault(); handleAddInput('cr-options-missiles') }}>{t("Add More")}</a>
                                </div>
                            </div>
                            <div className="cr-options-player cr-options-box">
                                <h4>{t("Attacker(s)")} (extra)</h4>
                                <div id="cr-options-raids">
                                    <label>{t("FollowUps")}</label>
                                    <input type="text" name="raids[]" placeholder="CR-KEY" pattern="cr-[a-z]{2}-\d{1,3}-\w{40}" onChange={(e) => handleInputChange(e, 'cr-options-raids')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-raids-add" className="cr-option-add" data-container-id="cr-options-raids" onClick={(e) => { e.preventDefault(); handleAddInput('cr-options-raids') }}>{t("Add More")}</a>
                                </div>
                                <div id="cr-attacker-deut">
                                    <label>{t("Deuterium costs")}</label>
                                    <input type="text" name="attacker_deut[]" placeholder={t("Deuterium")} onChange={(e) => handleInputChange(e, 'cr-attacker-deut')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-att-deut-add" className="cr-option-add" data-container-id="cr-attacker-deut" onClick={(e) => { e.preventDefault(); handleAddInput('cr-attacker-deut') }}>{t("Add More")}</a>
                                </div>
                            </div>
                            <div className="cr-options-player cr-options-box">
                                <h4>{t("Defender(s)")}</h4>
                                <div id="cr-defender-df">
                                    <label>{t("Harvest reports")}</label>
                                    <input type="text" name="defender_df[]" placeholder={t("Harvest report key")} pattern="rr-[a-z]{2}-\d{1,3}-\w{40}" onChange={(e) => handleInputChange(e, 'cr-defender-df')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-def-df-add" className="cr-option-add" data-container-id="cr-defender-df" onClick={(e) => { e.preventDefault(); handleAddInput('cr-defender-df') }}>{t("Add More")}</a>
                                </div>
                                <div id="cr-defender-deut">
                                    <label>{t("Deuterium costs")}</label>
                                    <input type="text" name="defender_deut[]" placeholder={t("Deuterium")} onChange={(e) => handleInputChange(e, 'cr-defender-deut')} />
                                    <a style={{border: 'none',background: 'none',textDecoration: 'underline',cursor: 'pointer',padding: 0,font: 'inherit'}} id="cr-options-def-deut-add" className="cr-option-add" data-container-id="cr-defender-deut" onClick={(e) => { e.preventDefault(); handleAddInput('cr-defender-deut') }}>{t("Add More")}</a>
                                </div>
                            </div>
                        </div>

                        <div id="cr-options-adv-container" className="cr-options-container clearfix">
                            <div id="cr-adv-colors" className="cr-options-adv-box">
                                <div id="cr-options-actions">
                                    <label id="cr-options-show">
                                        <input
                                            type="checkbox"
                                            name="option_save_options"
                                            id="cr-save-options"
                                            checked={saveOptions}
                                            onChange={handleSaveOptionsChange}
                                        />{' '}
                                        {t("Save my options")}
                                        {/* <input type="checkbox" name="option_save_options" id="cr-save-options" /> {t("Save my options")} */}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="cr-buttons">
                        <Button
                            disabled={Loading}
                            className="cr-button"
                            onClick={handleFormSubmit}>
                            <i className={`fas fa-${Loading ? "spinner" : "chevron-left"}`} />
                            {Loading ? t("Loading") : t("Convert")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}