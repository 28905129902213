import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18n from "i18next";
import { initReactI18next } from "react-i18next"; 
import LanguageDetector from 'i18next-browser-languagedetector'
import English from "./I18n/English.json";
import French from "./I18n/Francais.json";
import Spain from "./I18n/Spain.json";
import German from "./I18n/German.json";
import Turkish from "./I18n/Turkish.json";
import Polish from "./I18n/Polish.json";
import Italian from "./I18n/Italian.json";
import Portugues from "./I18n/Portugues.json";

import './index.css';

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: English,
    fr: French,
    es: Spain,
    de: German,
    tr: Turkish,
    pl: Polish,
    it: Italian,
    pt: Portugues
  },
  lng: getLanguageFromLocalStorage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

function getLanguageFromLocalStorage() {
  if (!localStorage.language)
    localStorage.language = "en";
  return localStorage.language;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);